.bg[data-v-3e8c4b94] {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}
.bg .des_info[data-v-3e8c4b94] {
  color: #fc7474;
  text-align: center;
  width: 600px;
  margin: 0 auto;
  clear: both;
}
.bg .address[data-v-3e8c4b94] {
  position: absolute;
  width: 654px;
  height: 476px;
  top: 100px;
  left: 0;
  right: 0;
  margin: auto;
  background: url(../images/address.png) no-repeat;
}
.bg .address .close_button[data-v-3e8c4b94] {
  position: absolute;
  width: 40px;
  height: 40px;
  top: -3px;
  right: -3px;
  cursor: pointer;
}
.bg .address .form[data-v-3e8c4b94] {
  width: 550px;
  height: auto;
  margin: 0 auto;
  margin-top: 100px;
}
.bg .address .form[data-v-3e8c4b94] .el-form-item__content {
  line-height: 0px !important;
}
.bg .address .form[data-v-3e8c4b94] .el-form-item__error {
  margin-left: 90px;
}
.bg .address .form[data-v-3e8c4b94] .el-textarea {
  width: auto;
}
.bg .address .form .form_ele[data-v-3e8c4b94] {
  width: 50%;
  float: left;
  margin-bottom: 35px;
}
.bg .address .form .form_ele .el-input[data-v-3e8c4b94] {
  width: auto;
}
.bg .address .form .form_ele[data-v-3e8c4b94] span {
  color: red;
  margin-right: 5px;
}
.bg .address .form .form_ele[data-v-3e8c4b94] input {
  display: inline-block;
  width: 145px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #d1d1d1;
  -webkit-border-radius: 4px;
          border-radius: 4px;
  background: #fafafa;
}
.bg .address .form .form_ele2[data-v-3e8c4b94] {
  width: 100%;
}
.bg .address .form .form_ele2[data-v-3e8c4b94] .el-button,
.bg .address .form .form_ele2[data-v-3e8c4b94] .el-button:focus,
.bg .address .form .form_ele2[data-v-3e8c4b94] .el-button:hover {
  background: #fc7474;
  border: none;
  margin: 0 auto;
  display: block;
}
.bg .address .form .form_ele2[data-v-3e8c4b94] .el-button span,
.bg .address .form .form_ele2[data-v-3e8c4b94] .el-button:focus span,
.bg .address .form .form_ele2[data-v-3e8c4b94] .el-button:hover span {
  color: white;
}
.bg .address .form .form_ele2[data-v-3e8c4b94] span {
  color: red;
  margin-right: 5px;
}
.bg .address .form .form_ele2[data-v-3e8c4b94] input {
  display: inline-block;
  width: 147px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #d1d1d1;
  -webkit-border-radius: 4px;
          border-radius: 4px;
  background: #fafafa;
}
.bg .address .form .form_ele2[data-v-3e8c4b94] textarea {
  display: inline-block;
  width: 420px;
  height: 110px;
  padding: 10px;
  line-height: 30px;
  border: 1px solid #d1d1d1;
  -webkit-border-radius: 4px;
          border-radius: 4px;
  background: #fafafa;
}
.bg .address .back[data-v-3e8c4b94] {
  position: absolute;
  top: 0;
  right: 0;
  width: 29px;
  height: 29px;
  line-height: 80px;
  text-align: center;
  font-size: 30px;
}
