.loader[data-v-ba948448] {
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}
.loader .loader_img[data-v-ba948448] {
  background: url(../images/loader.gif) no-repeat;
  position: fixed;
  width: 165px;
  height: 108px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
