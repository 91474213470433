.light_lump[data-v-76816d6c] {
  position: absolute;
  background: url(../images/light.png) no-repeat;
  width: 1077px;
  height: 573px;
  top: 203px;
  left: 422px;
}
.light_lump .light_01[data-v-76816d6c] {
  position: absolute;
  background: url(../images/light_01.png) no-repeat;
  width: 1077px;
  height: 573px;
  top: 0;
  left: 0;
}
.light_lump .light_02[data-v-76816d6c] {
  position: absolute;
  background: url(../images/light_02.png) no-repeat;
  width: 1077px;
  height: 573px;
  top: 0;
  left: 0;
}
