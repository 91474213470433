.display_box[data-v-0a5adfa2] {
  position: fixed;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  z-index: -100;
  display: none;
}
.pop_get[data-v-0a5adfa2] {
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 500;
}
.pop_get .lump[data-v-0a5adfa2] {
  position: absolute;
  background: url(../images/pop_gift.png) no-repeat;
  width: 560px;
  height: 509px;
  top: 60px;
  left: 0;
  right: 0;
  margin: auto;
}
.pop_get .lump p[data-v-0a5adfa2] {
  display: block;
  position: absolute;
  top: 230px;
  left: 0;
  right: 0;
  margin: auto;
}
.pop_get .lump p .btn[data-v-0a5adfa2] {
  background: url(../images/btn.png) no-repeat;
  width: 105px;
  height: 33px;
  line-height: 35px;
  color: #3f0600;
  font-size: 15px;
  text-align: center;
  -webkit-border-radius: 8px;
          border-radius: 8px;
  display: inline-block;
  cursor: pointer;
  margin: 8px 10px;
}
.pop_get .lump p .btn_gray[data-v-0a5adfa2] {
  background: url(../images/btn_gray.png) no-repeat;
  width: 105px;
  height: 33px;
  line-height: 35px;
  color: #3f0600;
  font-size: 15px;
  text-align: center;
  -webkit-border-radius: 8px;
          border-radius: 8px;
  display: inline-block;
  cursor: pointer;
  margin: 8px 10px;
}
.pop_get .lump p span[data-v-0a5adfa2] {
  display: block;
  color: white;
  font-size: 22px;
  text-align: center;
  margin: 5px 0;
}
.pop_get .lump .close[data-v-0a5adfa2] {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 101px;
  right: 120px;
  margin: auto;
  cursor: pointer;
}
.rules[data-v-0a5adfa2] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 101;
}
.rules .rules_main[data-v-0a5adfa2] {
  width: 629px;
  height: 634px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -315px;
  margin-top: -317px;
  background: url(../images/rules_background.png) no-repeat center;
  -webkit-background-size: 100% 100%;
          background-size: 100%;
}
.rules .rules_main .close_button[data-v-0a5adfa2] {
  width: 29px;
  height: 29px;
  position: absolute;
  top: -25px;
  right: -25px;
  background: url(../images/close_button.png) no-repeat center;
  -webkit-background-size: 100% 100%;
          background-size: 100%;
  cursor: pointer;
}
.rules .rules_main .title[data-v-0a5adfa2] {
  width: 100%;
  height: 80px;
  line-height: 80px;
  font-size: 20px;
  color: #ed3b3b;
  text-align: center;
}
.rules .rules_main span[data-v-0a5adfa2] {
  padding: 0 30px;
  text-align: left;
  font-size: 14px;
  line-height: 26px;
  display: block;
}
.pop_over[data-v-0a5adfa2] {
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 500;
}
.pop_over .lump[data-v-0a5adfa2] {
  position: absolute;
  background: url(../images/pop_over.png) no-repeat;
  width: 419px;
  height: 289px;
  top: 150px;
  left: 0;
  right: 0;
  margin: auto;
}
.pop_over .lump .close[data-v-0a5adfa2] {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 26px;
  right: 0;
  margin: auto;
  cursor: pointer;
}
.data_lump[data-v-0a5adfa2] {
  position: absolute;
  top: 0;
  left: 0;
}
.data_lump .score[data-v-0a5adfa2] {
  position: absolute;
  top: 874px;
  left: 635px;
  font-size: 22px;
  color: #fff000;
  z-index: 100;
  cursor: pointer;
}
.data_lump .playTimes[data-v-0a5adfa2] {
  position: absolute;
  top: 480px;
  left: 876px;
  font-size: 16px;
  color: #fe2929;
  z-index: 100;
  width: 170px;
  text-align: center;
  cursor: pointer;
}
.bottom[data-v-0a5adfa2] {
  width: 600px;
  height: 44px;
  position: absolute;
  left: 50%;
  bottom: 120px;
  margin-left: -300px;
}
.bottom span[data-v-0a5adfa2] {
  display: inline-block;
  height: 44px;
  line-height: 44px;
  color: white;
  font-size: 14px;
  margin-right: 10px;
}
.bottom p[data-v-0a5adfa2] {
  font-size: 16px;
  color: #fff000;
  display: inline-block;
  height: 44px;
  line-height: 44px;
}
.bottom .box1[data-v-0a5adfa2] {
  width: 121px;
  height: 42px;
  border: 1px solid white;
  -webkit-border-radius: 8px;
          border-radius: 8px;
  color: white;
  font-size: 16px;
  line-height: 42px;
  text-align: center;
  margin-right: 15px;
  margin-left: 15px;
  cursor: pointer;
  display: inline-block;
}
.animate[data-v-0a5adfa2] {
  position: absolute;
  width: 945px;
  height: 433px;
  top: 220px;
  left: 487px;
  z-index: 5;
}
.animate li.bonus_1[data-v-0a5adfa2] {
  background: url(../images/1.png?v=1) no-repeat;
  position: absolute;
  display: block;
  width: 945px;
  height: 433px;
  top: 52px;
  left: 0;
}
.animate li.bonus_2[data-v-0a5adfa2] {
  background: url(../images/2.png?v=1) no-repeat;
  position: absolute;
  display: block;
  width: 945px;
  height: 433px;
  top: 52px;
  left: 0;
}
.animate li.bonus_3[data-v-0a5adfa2] {
  background: url(../images/3.png?v=1) no-repeat;
  position: absolute;
  display: block;
  width: 945px;
  height: 433px;
  top: 52px;
  left: 0;
}
.animate li.bonus_4[data-v-0a5adfa2] {
  background: url(../images/4.png?v=1) no-repeat;
  position: absolute;
  display: block;
  width: 945px;
  height: 433px;
  top: 52px;
  left: 0;
}
.animate li.bonus_5[data-v-0a5adfa2] {
  background: url(../images/5.png?v=1) no-repeat;
  position: absolute;
  display: block;
  width: 945px;
  height: 433px;
  top: 52px;
  left: 0;
}
.animate li.bonus_6[data-v-0a5adfa2] {
  background: url(../images/6.png?v=1) no-repeat;
  position: absolute;
  display: block;
  width: 945px;
  height: 433px;
  top: 52px;
  left: 0;
}
.animate li.bonus_7[data-v-0a5adfa2] {
  background: url(../images/7.png?v=1) no-repeat;
  position: absolute;
  display: block;
  width: 945px;
  height: 433px;
  top: 52px;
  left: 0;
}
.animate li.bonus_8[data-v-0a5adfa2] {
  background: url(../images/8.png?v=1) no-repeat;
  position: absolute;
  display: block;
  width: 945px;
  height: 433px;
  top: 52px;
  left: 0;
}
.animate li.bonus_9[data-v-0a5adfa2] {
  background: url(../images/9.png) no-repeat;
  position: absolute;
  display: block;
  width: 945px;
  height: 433px;
  top: 52px;
  left: 0;
}
.animate li.bonus_10[data-v-0a5adfa2] {
  background: url(../images/10.png) no-repeat;
  position: absolute;
  display: block;
  width: 945px;
  height: 433px;
  top: 52px;
  left: 0;
}
.animate li.bonus_11[data-v-0a5adfa2] {
  background: url(../images/11.png) no-repeat;
  position: absolute;
  display: block;
  width: 945px;
  height: 433px;
  top: 52px;
  left: 0;
}
.animate li.bonus_12[data-v-0a5adfa2] {
  background: url(../images/12.png) no-repeat;
  position: absolute;
  display: block;
  width: 945px;
  height: 433px;
  top: 52px;
  left: 0;
}
.animate li.bonus_13[data-v-0a5adfa2] {
  background: url(../images/13.png) no-repeat;
  position: absolute;
  display: block;
  width: 945px;
  height: 433px;
  top: 52px;
  left: 0;
}
.animate li.bonus_14[data-v-0a5adfa2] {
  background: url(../images/14.png) no-repeat;
  position: absolute;
  display: block;
  width: 945px;
  height: 433px;
  top: 52px;
  left: 0;
}
.animate li.bonus_15[data-v-0a5adfa2] {
  background: url(../images/15.png) no-repeat;
  position: absolute;
  display: block;
  width: 945px;
  height: 433px;
  top: 52px;
  left: 0;
}
.btn_normal[data-v-0a5adfa2] {
  position: absolute;
  background: url(../images/btn_normal.png?v=1) no-repeat;
  width: 173px;
  height: 139px;
  top: 401px;
  left: 874px;
  z-index: 20;
  cursor: pointer;
}
.btn_active[data-v-0a5adfa2] {
  position: absolute;
  background: url(../images/btn_active.png?v=1) no-repeat;
  width: 173px;
  height: 139px;
  top: 401px;
  left: 874px;
  z-index: 10;
  cursor: pointer;
}
.bg[data-v-0a5adfa2] {
  position: absolute;
  background: url(../images/bonus_bg.png?v=1) no-repeat;
  width: 945px;
  height: 433px;
  top: 271px;
  left: 487px;
}
