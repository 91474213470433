* {
  margin: 0;
  padding: 0;
}
* {
  margin: 0;
  padding: 0;
  outline: none;
  font-weight: normal !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
body {
  font-family: "微软雅黑", "黑体";
}
ul,
ol {
  list-style: none;
}
img {
  border: none;
  overflow: hidden;
}
a {
  text-decoration: none;
}
table,
td {
  border-collapse: collapse;
}
.clear_div {
  clear: both;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}
body {
  background: black;
}
#loadingImg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../images/loader.gif) no-repeat center rgba(0, 0, 0, 0.3);
  -webkit-background-size: 30% 30%;
          background-size: 30%;
  z-index: 100;
}
