.lump[data-v-54ec3a80] {
  position: relative;
}
.btn[data-v-54ec3a80] {
  position: absolute;
  width: 120px;
  height: 35px;
  top: 862px;
  left: 868px;
  z-index: 99;
  cursor: pointer;
}
.log[data-v-54ec3a80] {
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
}
.log .lump[data-v-54ec3a80] {
  position: fixed;
  background: url(../images/log.png) no-repeat;
  top: 50px;
  right: 0;
  left: 0;
  margin: auto;
  width: 677px;
  height: 664px;
}
.log .lump .lunp_tips[data-v-54ec3a80] {
  position: absolute;
  width: 100%;
  bottom: 7px;
  font-size: 12px;
  text-align: center;
  color: #fff;
}
.log .lump .page_num[data-v-54ec3a80] {
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 26px;
}
.log .lump .page_num .el-pagination[data-v-54ec3a80] {
  text-align: center;
}
.log .lump .server[data-v-54ec3a80] {
  width: 100px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #ff6c6c;
  border: 1px solid #ff6c6c;
  -webkit-border-radius: 15px;
          border-radius: 15px;
  position: absolute;
  top: 47px;
  right: 63px;
  cursor: pointer;
}
.log .lump .list[data-v-54ec3a80] {
  position: absolute;
  top: 120px;
  left: 36px;
  width: 604px;
  height: 486px;
  overflow-y: auto;
}
.log .lump .list[data-v-54ec3a80]::-webkit-scrollbar {
  width: 10px;
  height: 1px;
}
.log .lump .list[data-v-54ec3a80]::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
          border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #535353;
}
.log .lump .list[data-v-54ec3a80]::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 10px;
          border-radius: 10px;
  background: #EDEDED;
}
.log .lump .list .ele[data-v-54ec3a80] {
  width: 100%;
  height: 33px;
  margin-bottom: 15px;
  overflow: hidden;
}
.log .lump .list .ele .counter[data-v-54ec3a80] {
  float: left;
  width: 47px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  overflow: hidden;
}
.log .lump .list .ele .date[data-v-54ec3a80] {
  float: left;
  width: 201px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  overflow: hidden;
}
.log .lump .list .ele .gift[data-v-54ec3a80] {
  float: left;
  width: 217px;
  height: 30px;
  color: #ff0000;
  line-height: 30px;
  text-align: center;
  overflow: hidden;
  margin-left: 1px;
}
.log .lump .list .ele .btn_lump[data-v-54ec3a80] {
  float: left;
  width: 124px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  overflow: hidden;
}
.log .lump .list .ele .btn_lump .btn[data-v-54ec3a80] {
  float: left;
  position: static;
  background: #fca600;
  width: 110px;
  height: 26px;
  line-height: 26px;
  color: white;
  font-size: 15px;
  text-align: center;
  -webkit-border-radius: 8px;
          border-radius: 8px;
  cursor: pointer;
}
.log .lump .list .ele .btn_lump .btn_gray[data-v-54ec3a80] {
  float: left;
  position: static;
  background: transparent;
  border: 1px solid #fc7474;
  width: 77px;
  height: 26px;
  line-height: 26px;
  color: #fc7474;
  font-size: 15px;
  text-align: center;
  -webkit-border-radius: 8px;
          border-radius: 8px;
  cursor: pointer;
}
.log .lump .list .ele .btn_lump .btn2[data-v-54ec3a80] {
  display: block;
  background: #fc7474;
  width: 77px;
  height: 26px;
  line-height: 26px;
  color: white;
  font-size: 15px;
  text-align: center;
  -webkit-border-radius: 8px;
          border-radius: 8px;
  margin: 0 auto;
  cursor: pointer;
}
.log .lump .list .ele .btn_lump .btn2_gray[data-v-54ec3a80] {
  display: block;
  background: #c1c1c1;
  width: 77px;
  height: 26px;
  line-height: 26px;
  color: white;
  font-size: 15px;
  text-align: center;
  -webkit-border-radius: 8px;
          border-radius: 8px;
  margin: 0 auto;
  cursor: pointer;
}
.log .lump .close[data-v-54ec3a80] {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
